// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import mixpanel from "./mixpanelReducer"
import navbar from "./navbar"
import layout from "./layout"
import chat from "@src/views/apps/chat/store/reducer"
import todo from "@src/views/apps/todo/store/reducer"
import users from "@src/views/apps/user/store/reducer"
import email from "@src/views/apps/email/store/reducer"
import invoice from "@src/views/apps/invoice/store/reducer"
import calendar from "@src/views/apps/calendar/store/reducer"

import ecommerce from "@src/views/apps/ecommerce/store/reducer"
import dataTables from "@src/views/tables/data-tables/store/reducer"

import categories from "@src/views/tables/data-tables/store/reducer/categories"
import mainCategories from "@src/views/tables/data-tables/store/reducer/mainCategories"
import promotions from "@src/views/tables/data-tables/store/reducer/promotions"
import mattoEvents from "@src/views/tables/data-tables/store/reducer/mattoEvents"
import rewards from "@src/views/tables/data-tables/store/reducer/rewards"
import orders from "@src/views/tables/data-tables/store/reducer/orders"
import ordersTransfer from "@src/views/tables/data-tables/store/reducer/orderTransfer"
import locations from "@src/views/tables/data-tables/store/reducer/locations"
import modifiers from "@src/views/tables/data-tables/store/reducer/modifiers"
import products from "@src/views/tables/data-tables/store/reducer/products"
import customers from "@src/views/tables/data-tables/store/reducer/customers"
import balance from "@src/views/tables/data-tables/store/reducer/balance"
import managers from "@src/views/tables/data-tables/store/reducer/managers"
import iam from "@src/views/tables/data-tables/store/reducer/iam"

import reports from "@src/views/tables/data-tables/store/reducer/reports"
import activityLogs from "@src/views/tables/data-tables/store/reducer/activityLogs"
import syncAccounts from "@src/views/tables/data-tables/store/reducer/syncAccounts"
import coupons from "@src/views/tables/data-tables/store/reducer/coupons"
import vouchers from "@src/views/tables/data-tables/store/reducer/vouchers"
import giftCards from "@src/views/tables/data-tables/store/reducer/giftCards"
import notifications from "@src/views/tables/data-tables/store/reducer/notifications"
import prodStock from "@src/views/tables/data-tables/store/reducer/prodStock"

const rootReducer = combineReducers({
  auth,
  mixpanel,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  modifiers,
  products,
  categories,
  promotions,
  rewards,
  orders,
  locations,
  customers,
  balance,
  managers,
  iam,
  reports,
  activityLogs,
  syncAccounts,
  coupons,
  vouchers,
  giftCards,
  mattoEvents,
  notifications,
  mainCategories,
  ordersTransfer,
  prodStock,
})

export default rootReducer
