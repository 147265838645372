const initialState = {
  managersData: {},
  managersAndAdminData: [],
  hasComponentMountedOnce: false,
  isLoading: true,
  currentPage: 0,
}

const iAMReducer = (state = initialState, action) => {
  switch (action.type) {

    case "ADD_IAM_NEW_MANAGERS": {
      const newManagers = { ...state.managersData }
      action.data.managers.forEach((manager) => {
        newManagers[manager.id] = manager
      })
      return {
        ...state,
        managersData: newManagers,
        totalManagers: Object.keys(newManagers).length,
      }
    }
    case "DELETE_IAM_MANAGER": {
      const newManagers = { ...state.managersData }
      delete newManagers[action.data.managerId]
      return {
        ...state,
        managersData: newManagers,
      }
    }
    case "UPDATE_IAM_MANAGER": {
      const newManagers = { ...state.managersData }
      newManagers[action.data.id] = { ...newManagers[action.data.id], ...action.data }
      return { ...state, managersData: newManagers }
    }
    case "SET_IAM_MANAGER_LOADING": {
      return { ...state, isLoading: action.data }
    }
    case "SET_IAM_MANAGER_MOUNTED": {
      return { ...state, hasComponentMountedOnce: true }
    }
    case "SET_IAM_MANAGER_CURRENT_PAGE": {
      return { ...state, currentPage: action.data }
    }

    case "RESET_IAM_MANAGER_FILTERS_AND_DATA": {
      return {
        ...state,
        managersData: [],
        currentPage: 0,
      }
    }
    default:
      return state
  }
}

export default iAMReducer
